import React from 'react'
const LabelValue = Loader.loadBaseComponent('LabelValue')

export default ({
  captureTime,
  deviceName
}) => {
  return <div className='box-container'>
    <div className='header'>
      <LabelValue className="item" label="抓拍地点" value={deviceName ? deviceName : '-'} />
      <LabelValue className="item" label="抓拍时间" value={Utils.formatTimeStamp(parseInt(captureTime, 10))} />
    </div>
  </div>
}